<template>

  <div class="full-width">
    <!-- 头部 -->
    <live-head @event="eventSubmit"></live-head>
    <!-- 内容 -->
    <div class="live_body flexcc flex-column">
      <div class="size42 mrb40">合作优势</div>
      <div class="advantage_box  flexcc">
        <div class="item_box flexcc flex-column mrr50 pd20 border-box align-center" v-for="(item,index) in advantageList" :key="index">
          <div class="mrb10 item_box_img">
            <el-image style="width: 100%; height: 100%" :src="item.url" :fit="fit"></el-image>
          </div>
          <div class="size20 mrb10">{{ item.title }}</div>
          <div class="item_box_title size16">{{ item.counent }}</div>
        </div>
      </div>
    </div>
    <!-- 服务平台 -->
    <div class="flex-column mrb50">
      <div class="size42 flexcc mrb50">
        成熟的校园生活服务平台
      </div>
      <div class="flex flexcc">
        <div class="mrr30">
          <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/fuwupingtai.png')" :fit="fit"></el-image>
        </div>
        <div class="item_counter  flex-column size20">
          <div class="mrb60">
            校趣生活聚焦校园市场和学生群体的日常需求，主要围绕大学生吃穿住玩用行出发，从用户和平台两个维度来解决校园消费市场的问题。
          </div>
          <div class="mrb60">
            并通过筛选、汇聚全网优质资源，为大学生提供最实惠的消费通道。
          </div>
          <div class="mrb60">
            以“商城+外卖服务”作为入口，深挖大学生实际需求的基础细分校园市场，建立校园生活020全生态链移动互联网平台
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <relation-our id="page"></relation-our>

    <!-- 底部 -->
    <page-footer :list="footerList"></page-footer>
  </div>
</template>

<script>
import liveHead from "./liveHead.vue";
import relationOur from "./relationOur.vue";
import pageFooter from "@/components/comment/pageFooter/index.vue";

export default {
  components: { liveHead, relationOur, pageFooter },
  props: {},
  data() {
    return {
      fit: "fill",
      footerList: [
        {
          url: require("@/assets/imgs/two1.jpg"),
          name: "校趣生活小程序",
        },
      ],
      advantageList: [
        {
          url: require("@/assets/imgs/ic_1.png"),
          title: "全方位服务平台",
          counent:
            "平台包含校园外卖、校园代购、校园跑腿、校原商城等常规综合性大学生服务板块，可通过平台实现对高校学子进行校园生活360度全方位服务",
        },
        {
          url: require("@/assets/imgs/ic_2.png"),
          title: "个性化系统服务",
          counent:
            "针对各学校特点，团队还能直接参与所在学校专属的个性化功能定制，由校趣开发团队免费提供开发服务",
        },
        {
          url: require("@/assets/imgs/ic_3.png"),
          title: "1v1针对性指导",
          counent:
            "无论你是小白还是大神，我们都有专人针对团队进行1v1免费赋能指导，为求各个团队能在校趣生活赋能加持下实习第一桶金",
        },
      ],
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {
    eventSubmit() {
      console.log("子组件触发事件");
      // document.getElementById("page").scrollIntoView();
      debugger;
      // 获取点击的按钮对应页面的id
      var PageId = document.querySelector("#page");
      // 打印出对应页面与窗口的距离
      console.log(PageId.offsetTop);
      window.scrollTo({
        top: PageId.offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.live_body {
  padding: 120px 0px;
  width: 100%;
  .advantage_box {
    width: 100%;
    .item_box {
      width: 21%;
      height: 286px;
    }
  }
}
.item_box_title {
  color: #858585;
  text-align: center;
}
.item_box_img {
  width: 112px;
  height: 112px;
}
.item_counter {
  width: 30%;
}
</style>