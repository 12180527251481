<template>

  <div class="shopping_page bg-white full-width">
    <div class="shopping_head flex justify-around">
      <div class=" shopping_head_left  flex-column ">
        <div class="shopping_head_top border-box">
          <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/xqyplogo.png')" :fit="fit"></el-image>
        </div>
        <div class="">
          <div class="size48 shopping_head_text bold">
            「分销+供应链」聚合模式
            打造高校私域流量电商平台</div>
          <div class="size20 mrt20">
            多元营销模式助力品牌生意增长
          </div>
        </div>
      </div>
      <div>
        <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/shopping_right.png')" :fit="fit"></el-image>
      </div>
    </div>
    <!-- 介绍 -->
    <div class="shopping_body flexcc flex">
      <div class="item_box flex align-center mrr40  flex-column " v-for="(item,index) in bodyList" :key="index">
        <div class="mrb28" style="width: 100%;">
          <el-image style="width: 100%; height: 100%" :src="item.url" :fit="fit"></el-image>
        </div>

        <div class="mrb16 size20">{{ item.title }}</div>
        <div class="shopping_body_text size16" v-if="item.state">入驻免加盟费</div>
        <div class="size16 shopping_body_text">{{ item.text }}</div>
      </div>
    </div>
    <!-- 联系我们 -->
    <contact-us></contact-us>
    <!-- 底部 -->
    <page-footer :list="footerList"></page-footer>
  </div>
</template>

<script>
import pageFooter from "@/components/comment/pageFooter/index.vue";

import contactUs from "./contactUs.vue";
export default {
  components: { contactUs, pageFooter },
  props: {},
  data() {
    return {
      fit: "fill",
      footerList: [
        {
          url: require("@/assets/imgs/three.jpg"),
          name: "校趣优品小程序",
        },
      ],
      bodyList: [
        {
          url: require("@/assets/imgs/ig_1.png"),
          title: "裂变流量",
          text: "高校营销大赛+分销社交裂变模式，用户在社交渠道经营卖货，挖掘全域浏览，精准触达",
        },
        {
          url: require("@/assets/imgs/ig_2.png"),
          title: "0加盟费",
          text: "提供专属客服一站式服务，全程跟进达成交易",
          state: 1,
        },
        {
          url: require("@/assets/imgs/ig_3.png"),
          title: "技术优势",
          text: "有自己的研发团队，会根据市场的走向，定期更新迭代，提高顾客满意度，提高竞争力",
        },
      ],
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
.shopping_head {
  width: 100%;
  height: 688px;
  background-image: url(../../assets/imgs/shopping_background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .shopping_head_top {
    width: 135px;
    height: 35px;
    margin-bottom: 30%;
    margin-top: 15%;
  }
  .shopping_head_left {
    margin-left: 10%;
    line-height: 30px;
    width: 600px;
  }
}
.shopping_head_text {
  line-height: 66px;
  text-align: left;
}
.shopping_body_text {
  margin: 0px 12px;
  color: #858585;
  text-align: center;
}
.shopping_body {
  width: 100%;
  padding: 100px 0px;
}
.item_box {
  width: 19.5%;
  height: 356px;
  border-radius: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}
.shopping_footer {
  width: 100%;
  height: 191px;
  background-color: #1e2427;
  padding: 0px 25%;
  box-sizing: border-box;

  .shopping_footer_text {
    text-align: center;
    line-height: 30px;
    margin-right: 30%;
  }
  .shopping_footer_img {
    width: 100px;
    height: 100px;
  }
}
</style>