<template>
  <div class="agency_head flex justify-around ">
    <div class=" agency_head_left  flex-column ">
      <div class="agency_head_top border-box">
        <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/logo.png')" :fit="fit"></el-image>
      </div>
      <div class="">
        <div class="size38">趣小兔扫码点餐系统</div>
        <div class="mrt20 size18">
          趣小兔基于校趣的强大系统和服务能力，以全国一级服务商的身份，背靠支付宝和微信支付的全面支持和扶持，打造智慧门店，数字化运营服务于千万商家的最佳好帮手
        </div>
      </div>
    </div>
    <div>
      <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/agency_right.png')" :fit="fit"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      fit: "fill",
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
.agency_head {
  width: 100%;
  height: 688px;
  background-image: url(../../../assets/imgs/agency_img.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .agency_head_top {
    width: 157px;
    height: 49px;
    margin-bottom: 55%;
    margin-top: 15%;
  }
  .agency_head_left {
    margin-left: 10%;
    line-height: 30px;
    width: 400px;
  }
}
</style>