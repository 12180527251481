<template>
  <!-- 校趣生活 -->
  <div class="live_out flexcc flex-column">
    <div class="size42 mrb10">与我们联系</div>
    <div class="size20 mrb50">将有专人为您提供专业答疑解惑</div>
    <div class="flex flexcc" style="width: 100%;">
      <div class=" flex-column Settled_content_left">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" :rules="loginRules" ref="loginForm">
          <el-form-item label="所在城市" prop="cityValue">
            <el-cascader placeholder="选择所在地区" :options="options" filterable v-model="formInline.cityValue" :props="{ value: 'id',label:'name' }" style="width: 600px;"></el-cascader>
          </el-form-item>
          <el-form-item label="所在学校" prop="school">
            <el-input v-model="formInline.school" placeholder="选填、输入意向代理学校" style="width: 600px"></el-input>
          </el-form-item>

          <div class="flex">
            <el-form-item label="电话号码" prop="mobile">
              <el-input v-model="formInline.mobile" placeholder="请输入您的手机号" style="width: 250px"></el-input>
            </el-form-item>
            <el-form-item label="称呼" prop="name">
              <el-input v-model="formInline.name" placeholder="请输入您的姓名" style="width: 300px">
                <el-radio-group v-model="formInline.sex" slot="suffix">
                  <el-radio label="1">先生</el-radio>
                  <el-radio label="2">小姐</el-radio>
                </el-radio-group>
              </el-input>
            </el-form-item>
          </div>
          <el-form-item v-if="captcha" prop="code" label="验证码">
            <div class="capchat">
              <el-input class="input" v-model="formInline.code" style="width: 400px; margin-right: 15px" placeholder="请输入验证码" />
              <div class="img" :style="{ backgroundImage: `url(${captcha})` }" />
              <el-button type="text" style="font-size: 14px" @click="getCode">换一组</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit(formInline)">提交申请</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class=" Settled_content_right  align-center flex-column justify-center">
        <div class="mrb10 size20">加客服获取一对一指导</div>
        <div class="boredr"></div>
        <div class="Settled_content_images">
          <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/xqsh_code.jpg')" :fit="fit"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const city = require("@/utils/area.json");
export default {
  components: {},
  props: {},
  data() {
    return {
      captcha: "",
      fit: "fill",
      options: city,
      formInline: {
        cityValue: "",
        mobile: "",
        key: "",
        code: "",
        sex: "0",
        name: "",
        type: 2,
        school: "",
        province_id: "",
      },
      loginRules: {
        cityValue: [
          {
            required: true,
            trigger: "blur",
            message: "请选择城市",
          },
        ],
        mobile: [
          {
            required: true,
            trigger: "blur",
            message: "请输入电话号码",
          },
        ],
        school: [
          {
            required: true,
            trigger: "blur",
            message: "请输入学校名称",
          },
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名、性别",
          },
        ],
        code: [
          {
            required: true,
            trigger: "blur",
            message: "请输入验证码",
          },
        ],
      },
    };
  },

  created() {
    this.getCode();
  },
  mounted() {},
  computed: {},

  methods: {
    async getCode() {
      const { data } = await this.$Api.getCode();
      console.log("data", data);
      this.captcha = data.img;
      this.formInline.key = data.key;
    },
    async onSubmit(from) {
      this.$refs.loginForm.validate((valid) => {});
      const [province_id, city_id, area_id] = from.cityValue;

      let parameter = { ...from, province_id, city_id, area_id };
      console.log(parameter, "提交的表单");
      const { msg, code } = await this.$Api.agentAdd(parameter);
      switch (code) {
        case 200:
          this.$message({
            message: "提交成功，工作人员会与你联系",
            type: "success",
          });
          this.formInline = {
            cityValue: "",
            mobile: "",
            key: "",
            code: "",
            sex: "0",
            name: "",
            type: 2,
            school: "",
            province_id: "",
          };
          this.getCode();
          break;
        default:
          this.getCode();

          this.$message({
            message: msg,
            type: "error",
          });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.live_out {
  padding: 50px 0px;
  width: 100%;
  background-color: #f5f9ff;
}
::v-deep .el-input__inner {
  border-radius: 30px;
}
::v-deep .el-button--primary {
  width: 600px;
  margin-left: 69px;
  border-radius: 30px;
  background-color: #3370ff;
  border-color: #3370ff;
}
::v-deep .el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Settled_content_right {
  margin-left: 100px;
  .Settled_content_images {
    width: 215px;
    height: 215px;
    border-radius: 30px;
  }
  ::v-deep .el-image__inner {
    border-radius: 30px;
  }
}
.capchat {
  display: flex;
  .img {
    height: 45px;
    width: 120px;
    background-size: 100% 100%;
    margin-right: 16px;
  }
}
</style>