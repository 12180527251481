<template>
  <!-- 趣小兔 -->
  <div class="cooperativeAgency_page bg-white ">
    <!-- 头部 -->
    <quxiaotu-head></quxiaotu-head>

    <!-- {{value}} -->
    <div class="agency_content flex-column flexcc">
      <div class="mrb10 size42">与我们联系</div>
      <div class="size20 mrb40">将有专人为您提供专业答疑解惑</div>
      <div class="flex flexcc" style="width: 100%">
        <div class=" flex-column agency_content_left">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" :rules="loginRules" ref="loginForm" label-width="100px">
            <el-form-item label="所在城市" prop="cityValue">
              <el-cascader placeholder="选择所在地区" :options="options" filterable v-model="formInline.cityValue" :props="{ value: 'id',label:'name' }" style="width: 400px;"></el-cascader>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="formInline.mobile" placeholder="请输入您的手机号" style="width: 400px;"></el-input>
            </el-form-item>
            <el-form-item v-if="captcha" prop="code" label="验证码">
              <div class="capchat">
                <el-input class="input" v-model="formInline.code" style="width: 200px; margin-right: 15px" placeholder="请输入验证码" />
                <div class="img" :style="{ backgroundImage: `url(${captcha})` }" />
                <el-button type="text" style="font-size: 14px" @click="getCode">换一组</el-button>
              </div>
            </el-form-item>
            <el-button type="primary" @click="onSubmit(formInline)">提交申请</el-button>
          </el-form>
        </div>
        <div class=" agency_content_right  align-center flex-column justify-center">
          <div class="mrb10 size20">加客服获取一对一指导</div>
          <div class="boredr"></div>
          <div class="agency_content_images">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/qxt_code.jpg')" :fit="fit"></el-image>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <page-footer :list="footerList"></page-footer>
  </div>
</template>

<script>
import quxiaotuHead from "@/components/comment/quxiaotuHead/index.vue";
import pageFooter from "@/components/comment/pageFooter/index.vue";
const city = require("@/utils/area.json");
export default {
  components: { quxiaotuHead, pageFooter },
  props: {},
  data() {
    return {
      captcha: "",
      fit: "fill",
      cityValue: "",

      formInline: {
        mobile: "",
        key: "",
        code: "",
        type: 1,
      },
      loginRules: {
        cityValue: [
          {
            required: true,
            trigger: "blur",
            message: "请选择城市",
          },
        ],
        mobile: [
          {
            required: true,
            trigger: "blur",
            message: "请输入号码",
          },
        ],
        code: [
          {
            required: true,
            trigger: "blur",
            message: "请输入验证码",
          },
        ],
      },
      footerList: [
        {
          url: require("@/assets/imgs/one.jpg"),
          name: "趣小兔小程序",
        },
      ],
      options: city,
    };
  },

  created() {
    this.getCode();
  },
  mounted() {},
  computed: {},

  methods: {
    async getCode() {
      const { data } = await this.$Api.getCode();
      console.log("data", data);
      this.captcha = data.img;
      this.formInline.key = data.key;
    },
    async onSubmit(from) {
      this.$refs.loginForm.validate((valid) => {});
      const [province_id, city_id, area_id] = from.cityValue;
      // console.log("province_id", province_id);
      // console.log("city_id", city_id);
      // console.log("area_id", area_id);

      let parameter = { ...from, province_id, city_id, area_id };
      // console.log(parameter, "提交的表单");
      const { msg, code } = await this.$Api.agentAdd(parameter);
      switch (code) {
        case 200:
          this.$message({
            message: "提交成功，工作人员会与你联系",
            type: "success",
          });
          this.formInline = {
            mobile: "",
            key: "",
            code: "",
            type: 1,
          };
          this.getCode();
          break;
        default:
          this.getCode();

          this.$message({
            message: msg,
            type: "error",
          });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-input__inner {
  border-radius: 30px;
}
::v-deep .el-button--primary {
  width: 396px;
  margin-left: 97px;
  border-radius: 30px;
  background-color: #3370ff;
  border-color: #3370ff;
}
.agency_content_left {
  border-right: 1px solid #b3bcc9;
  padding-right: 100px;
}
.agency_content {
  padding: 50px 0px;
  width: 100%;
}
::v-deep .el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agency_content_right {
  margin-left: 100px;
  .agency_content_images {
    width: 215px;
    height: 215px;
    border-radius: 30px;
  }
  ::v-deep .el-image__inner {
    border-radius: 30px;
  }
}
.capchat {
  display: flex;
  .img {
    height: 45px;
    width: 120px;
    background-size: 100% 100%;
    margin-right: 16px;
  }
}
</style>