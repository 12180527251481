<template>
  <div class="live_head flex justify-around ">
    <div class=" live_head_left  flex-column ">
      <div class="live_head_top border-box">
        <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/xqshlogo.png')" :fit="fit"></el-image>
      </div>
      <div class="">
        <div class="size60">合作伙伴全国火热招商</div>
        <div class="mrt20" @click="Submit()">
          <el-button type="primary">立即加入</el-button>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->

    <div>
      <div class="w">
        <el-carousel height="688px">
          <el-carousel-item v-for="(item, index) in urlList" :key="index">
            <h3 class="small">
              <img :src="item.url" alt />
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      fit: "fill",
      urlList: [
        {
          url: require("@/assets/imgs/slideshow1.png"),
        },
        { url: require("@/assets/imgs/slideshow2.png") },
        {
          url: require("@/assets/imgs/slideshow3.png"),
        },
      ],
    };
  },

  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {},

  methods: {
    joinClick() {},
    Submit() {
      this.$emit("event");
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-button--primary {
  background-color: #3370ff;
  border-color: #3370ff;
}
.live_head {
  height: 688px;
  background-image: url(../../assets/imgs/agency_img.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .live_head_top {
    width: 205px;
    height: 44px;
    margin-bottom: 25%;
    margin-top: 15%;
  }
  .live_head_left {
    margin-left: 10%;
  }
}
.slide_pc {
  width: 656px;
  height: 668px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

//
// ::v-deep .el-carousel__item[data-v-1b9278e4]:nth-child(2n + 1) {
//   background-color: transparent !important;
// }
// ::v-deep .el-carousel__item[data-v-1b9278e4]:nth-child(2n) {
//   background-color: transparent !important;
// }
.w {
  margin: 0 auto;
  width: 656px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 1;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: transparent;
}

.small img {
  width: 100%;
  // height: 100%;
  // width: 656px;
}

.el-carousel__container {
  width: 1226px;
  height: 460px;
}
</style>