<template>
  <!-- 校趣优品 -->
  <div class="shopping_our flexcc  flex-column">
    <div class="size42 mrb10">申请入驻</div>
    <div class="size20 mrb50">填写信息，预约招商经理主动与您联系</div>
    <div class="flex flexcc" style="width: 100%;">
      <div class=" flex-column shopping_content_left">
        <el-form :model="formInline" class="demo-form-inline" :rules="loginRules" ref="loginForm" label-width="100px">
          <el-form-item label="所在城市" prop="cityValue">
            <el-cascader placeholder="选择所在地区" :options="options" filterable v-model="formInline.cityValue" :props="{ value: 'id',label:'name' }" style="width: 400px;"></el-cascader>
          </el-form-item>
          <el-form-item label="经营类别" prop="category">
            <el-input v-model="formInline.category" placeholder="请输入经营类别" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item label="品牌名称" prop="brand_name">
            <el-input v-model="formInline.brand_name" placeholder="请输入品牌名称" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item label="电话号码" prop="mobile">
            <el-input v-model="formInline.mobile" placeholder="请输入您的手机号" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item v-if="captcha" prop="code" label="验证码">
            <div class="capchat">
              <el-input class="input" v-model="formInline.code" style="width: 200px; margin-right: 15px" placeholder="请输入验证码" />
              <div class="img" :style="{ backgroundImage: `url(${captcha})` }" />
              <el-button type="text" style="font-size: 14px" @click="getCode">换一组</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit(formInline)">提交申请</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class=" shopping_content_right  align-center flex-column justify-center">
        <div class="mrb10 size20">加客服获取一对一指导</div>
        <div class="boredr"></div>
        <div class="shopping_content_images">
          <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/xqyp_code.jpg')" :fit="fit"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const city = require("@/utils/area.json");

export default {
  components: {},
  props: {},
  data() {
    return {
      options: city,
      fit: "fill",
      captcha: "",
      cityValue: "",
      formInline: {
        mobile: "",
        key: "",
        code: "",
        cityValue: "",
        type: 3,
      },
      loginRules: {
        cityValue: [
          {
            required: true,
            trigger: "blur",
            message: "请选择城市",
          },
        ],
        mobile: [
          {
            required: true,
            trigger: "blur",
            message: "请输入号码",
          },
        ],
        category: [
          {
            required: true,
            trigger: "blur",
            message: "请输入经营类别",
          },
        ],
        brand_name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入品牌名称",
          },
        ],
        code: [
          {
            required: true,
            trigger: "blur",
            message: "请输入验证码",
          },
        ],
      },
    };
  },

  created() {
    this.getCode();
  },
  mounted() {},
  computed: {},

  methods: {
    async getCode() {
      const { data } = await this.$Api.getCode();
      console.log("data", data);
      this.captcha = data.img;
      this.formInline.key = data.key;
    },
    async onSubmit(from) {
      this.$refs.loginForm.validate((valid) => {});
      const [province_id, city_id, area_id] = from.cityValue;
      let parameter = { ...from, province_id, city_id, area_id };
      console.log(parameter, "提交的表单");
      const { msg, code } = await this.$Api.agentAdd(parameter);
      switch (code) {
        case 200:
          this.$message({
            message: "提交成功，工作人员会与你联系",
            type: "success",
          });
          this.formInline = {
            cityValue: "",
            mobile: "",
            key: "",
            code: "",
            type: 3,
          };
          this.getCode();
          break;
        default:
          this.getCode();
          this.$message({
            message: msg,
            type: "error",
          });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.shopping_our {
  padding: 50px 0px;
  width: 100%;
  background-color: #fffafb;
}
::v-deep .el-input__inner {
  border-radius: 30px;
}
::v-deep .el-button--primary {
  width: 400px;
  border-radius: 30px;
  background-color: #d82b2a;
  border-color: #d82b2a;
}
::v-deep .el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shopping_content_right {
  margin-left: 100px;
  .shopping_content_images {
    width: 215px;
    height: 215px;
    border-radius: 30px;
  }
  ::v-deep .el-image__inner {
    border-radius: 30px;
  }
}
.shopping_content_left {
  border-right: 1px solid #b3bcc9;
  padding-right: 100px;
}
.capchat {
  display: flex;
  .img {
    height: 45px;
    width: 120px;
    background-size: 100% 100%;
    margin-right: 16px;
  }
}
</style>